import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,{staticClass:"mb-10"},[_c(VCardTitle,[_c('h2',[_vm._v("我很可爱，请给我钱！")])]),_c(VCardText,[_c(VImg,{attrs:{"src":require("../assets/images/wxmoney.jpg"),"max-height":"600","max-width":"250","contain":""}}),_c(VImg,{attrs:{"src":require("../assets/images/zfbmoney.jpg"),"max-height":"600","max-width":"250","contain":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }