import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,{staticClass:"mb-10"},[_c(VCardTitle,[_c('h2',{staticClass:"mb-10"},[_vm._v(" 管理您的账号 ")])]),_c(VCardText,[_c(VRow,{staticClass:"mb-5 pl-7"},[_c(VAvatar,{attrs:{"color":"primary","size":"128"}},[_c('img',{attrs:{"src":"https://cravatar.cn/avatar/5ed20f2960c5e87468dee55bfd3ec4ab?d=mp"}})])],1),_c(VRow,{staticClass:"pl-3 pr-3"},[_c(VCol,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.handleUserModify.apply(null, arguments)}}},[_c(VTextField,{staticClass:"dense",attrs:{"outlined":"","label":"用户名","autofocus":"","prepend-inner-icon":"mdi-account-box","disabled":""},model:{value:(_vm.$store.getters.username),callback:function ($$v) {_vm.$set(_vm.$store.getters, "username", $$v)},expression:"$store.getters.username"}}),_c(VTextField,{staticClass:"dense",attrs:{"outlined":"","label":"原密码","type":"password","prepend-inner-icon":"mdi-fingerprint-off","required":"","disabled":_vm.loading},model:{value:(_vm.original_password),callback:function ($$v) {_vm.original_password=$$v},expression:"original_password"}}),_c(VTextField,{staticClass:"dense",attrs:{"outlined":"","label":"新密码","rules":[
              v => !v || v.length > 8 || '至少9字符',
              v => !v || v.length < 21 || '至多20字符',
              v => {
                if (!v) return true;
                const pattern = /^.*[0-9].*$/;
                const pattern_w = /^.*[a-zA-Z].*$/;
                return (pattern.test(v) && pattern_w.test(v)) || '必须包含数字和字母';
              }
            ],"type":"password","prepend-inner-icon":"mdi-account-key","disabled":_vm.loading},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VTextField,{staticClass:"dense",attrs:{"outlined":"","label":"确认密码","rules":[v => v == _vm.password || '两次密码不一致'],"type":"password","prepend-inner-icon":"mdi-account-key-outline","required":"","disabled":_vm.loading},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}}),_c(VBtn,{attrs:{"block":"","depressed":"","color":"primary","type":"submit","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.handleUserModify.apply(null, arguments)}}},[_vm._v(" 修改 ")])],1),_c(VBtn,{staticClass:"mt-2",attrs:{"block":"","depressed":"","disabled":_vm.loading,"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_vm._v(" 注销 ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }