import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?[_c(VContainer,{staticClass:"d-flex align-center justify-center",attrs:{"fluid":""}},[_c(VRow,{staticClass:"text-center"},[_c(VCol,[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary","size":"64"}})],1)],1)],1),_c(VContainer,{staticClass:"d-flex align-center justify-center",attrs:{"fluid":""}},[_c(VRow,{staticClass:"text-center"},[_c(VCol,[_c('h3',[_vm._v("当年明月在，不见彩云归。")]),_c('span',[_vm._v("正在获取用户反馈。")])])],1)],1)]:[(_vm.feedbacks.length > 0)?_c(VExpansionPanels,{attrs:{"inset":"","focusable":""}},_vm._l((_vm.feedbacks),function(feedback,index){return _c(VExpansionPanel,{key:index},[_c(VExpansionPanelHeader,{attrs:{"disable-icon-rotate":!feedback.is_active}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","width":"100%"}},[_c('div',[_vm._v(_vm._s(feedback.name)+"的反馈")]),_c('div',{staticStyle:{"color":"grey","text-align":"right","margin-right":"16px"}},[_c('span',[_vm._v(_vm._s(_vm.formatDate(feedback.update_time)))])])])]),_c(VExpansionPanelContent,[_c('div',{staticClass:"panel-content"},[_c('div',[_c('strong',[_vm._v("Username:")]),_vm._v(" "+_vm._s(feedback.username))]),_c('div',[_c('strong',[_vm._v("Email:")]),_vm._v(" "+_vm._s(feedback.email))]),_c('div',[_c('strong',[_vm._v("Advice:")]),_vm._v(" "+_vm._s(feedback.advice))]),_c('div',[_c('strong',[_vm._v("Complaint:")]),_vm._v(" "+_vm._s(feedback.complaint))])])])],1)}),1):_c('h2',{staticClass:"d-flex justify-center align-center"},[_vm._v(" 好吧，看起来现在还没有反馈。 ")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }