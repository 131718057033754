import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"height":"100vh"}},[_c(VContainer,{staticClass:"text-center"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VImg,{attrs:{"src":require("../assets/images/404.png"),"max-width":"200","max-height":"200"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])}),_c(VCol,{attrs:{"cols":"4","justify":"center"}},[_c('h1',[_vm._v("404 - Page Not Found")]),_c('p',[_vm._v("Sorry, the page you are looking for does not exist.")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.goHome}},[_vm._v("返回首页。")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }